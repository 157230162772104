import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async FETCH_ALL_TRANSACTION({}, { search, paymentType, limit, page, sortBy, order }) {
      try {
        const {
          data,
        } = await apiClient('/admin/transaction-history', {
          method: 'GET',
          params: { search, paymentType, limit, page, sortBy, order },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_TRANSACTION_DETAIL({}, { id, idUser }) {
      try {
        const {
          data: { data },
        } = await apiClient(`/admin/user/transaction-history/${id}`, {
          method: 'GET',
          params: { idUser },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_STATUS_TRANSACTION({}, { xenditId }) {
      try {
        const {
          data: { data },
        } = await apiClient('/xendit/invoice/callback', {
          method: 'POST',
          params: {
            type: 'manual',
          },
          data: {
            xenditId,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
