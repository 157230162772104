import { createStore } from 'vuex'
import user from './user'
import settings from './settings'
import course from './course'
import topic from './topic'
import vendor from './vendor'
import banner from './banner'
import akun from './akun'
import transaction from './transaction'
import discountedCourse from './discountedCourse'
import admin from './admin'
import withdrawal from './withdrawal'
import creator from './creator'
import report from './report'

export default createStore({
  modules: {
    user,
    settings,
    course,
    topic,
    vendor,
    banner,
    akun,
    transaction,
    discountedCourse,
    admin,
    withdrawal,
    creator,
    report,
  },
  state: {},
  mutations: {},
  actions: {},
  getters: {},
})
