import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async FETCH_ALL_REPORT({}, { search, reportType, limit, page, sortBy, order }) {
      try {
        const {
          data,
        } = await apiClient('/admin/report', {
          method: 'GET',
          params: { search, reportType, limit, page, sortBy, order },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async BANNING_USER({}, { userId }) {
      try {
        const { data } = await apiClient(`/admin/ban/${userId}`, {
          method: 'PATCH',
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
