import router from '@/router'
import { notification } from 'ant-design-vue'
import apiClient from '@/services/axios'
import VueCookies from 'vue-cookies'
import store from 'store'

export default {
  namespaced: true,
  state: {
    id: '',
    name: '',
    role: '',
    email: '',
    avatar: '',
    authorized: false,
    loading: false,
    accountFetchIsTouched: false,
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async LOGIN({ commit, dispatch, rootState }, { email, password }) {
      try {
        const { data } = await apiClient.post('/account/login', {
          email,
          password,
        })
        // console.log('LOGIN data', data)
        const userToken = data?.token || null;
        store.set = userToken || null;
        localStorage.setItem('accessToken', userToken)
        return new Promise((resolve, reject) => {
          resolve({ user: data?.data, session: data?.data?._id, token: userToken })
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async LOGOUT({ commit, rootState }) {
      try {
        await apiClient.post('/admin/logout')
        VueCookies.remove('sessionId')
        VueCookies.remove('user')
        localStorage.clear()
        commit('SET_STATE', { sessionId: null })
        router.push({ name: 'masuk' })
      } catch (err) {
        console.log(err)
      }
    },
  },
  getters: {
    user: state => state,
  },
}
