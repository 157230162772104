import apiClient from '../../'
const token = localStorage.getItem('accessToken')

export const loginAsAdmin = async params => {
  const { email, password } = params
  const res = await apiClient.post('')
}

export const getAllUser = async params => {
  const { ...rest } = params
  const res = await apiClient.get(`/admin/all-user`, {
    params: { ...rest },
  })
  if (res) {
    return res.data
  } else {
    return []
  }
}

export const getDetailUser = async params => {
  const { _id } = params
  const res = await apiClient.get(`/user/${_id}`)
  if (res) {
    return res.data
  } else {
    return {}
  }
}
export const getContentUser = async params => {
  const { _id, ...rest } = params
  const res = await apiClient.get(`/content/me`, { params: { id: _id, ...rest } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
export const getStoryUser = async params => {
  const { _id, ...rest } = params
  const res = await apiClient.get(`/story/me`, { params: { id: _id, ...rest } })
  if (res) {
    return res.data
  } else {
    return []
  }
}
