import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    async FETCH_ALL_WITHDRAWAL({}, { search, status, limit, page, sortBy, order }) {
      try {
        const {
          data,
        } = await apiClient('/admin/creator/withdrawal', {
          method: 'GET',
          params: { search, status, limit, page, sortBy, order },
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_WITHDRAWAL_DETAIL({}, { id }) {
      try {
        const {
          data: { data },
        } = await apiClient(`/admin/creator/withdrawal/${id}`, {
          method: 'GET',
        })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {},
  getters: {
  },
}
