import { createApp } from 'vue'
import Antd from 'ant-design-vue'
import PerfectScrollbar from 'vue3-perfect-scrollbar'

import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from './store'
import vueChartist from 'vue-chartist'
import './mixins.scss'
import "./plugins/chart.js"

createApp(App)
  .use(store)
  .use(router)
  .use(Antd)
  .use(vueChartist)
  .use(PerfectScrollbar)
  .mount('#app')
