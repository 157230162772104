import axios from 'axios'
import store from 'store'
import { notification } from 'ant-design-vue'
import cookies from 'vue-cookies'
import router from '../../router'

// console.log('process.env.VUE_APP_BACKEND_URL', process.env.VUE_APP_BACKEND_URL)

const apiClient = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}api/v1`,
  // timeout: 2000,
  withCredentials: true,
  headers: {
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Origin': `${process.env.VUE_APP_BACKEND_URL}`,
    'Access-Control-Allow-Headers': 'accept',
    'Cross-Origin-Resource-Policy': 'cross-origin',
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

apiClient.interceptors.request.use(request => {
  const accessToken = store.get('accessToken')
  console.log('accessToken', accessToken)
  if (accessToken) {
    // request.headers.Authorization = `Bearer ${accessToken}`
    request.headers.token = accessToken
  }

  // request.headers.token =
  //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYzOWZlYjcwZDUyYjdlMzY4MGMxMThhNSIsImVtYWlsIjoibWVsdGFhcHBzQGdtYWlsLmNvbSIsImlhdCI6MTY3MTQ0MTYxNSwiZXhwIjoxNjc0MDMzNjE1fQ.-jOoWpGenBCMIo8SYDlSE1dpxgvP6NYwywVjLYiSbmE'
  return request
})

apiClient.interceptors.response.use(undefined, async err => {
  try {
    // Errors handling
    const { response } = err
    console.log(err.response)
    if (response?.data) {
      const title = response.data.status.split('_').join(' ')
      notification.warning({
        message: title,
        description: response.data.message || 'There must be some error. Please try again later.',
      })

      if (response.data.status === 'SESSION_TIMEOUT') {
        cookies.remove('sessionId')
        return router.push('/masuk')
      }
    } else {
      notification.warning({
        message: 'Internal Server Error',
        description: 'There must be some error. Please try again later.',
      })
    }
    return Promise.reject(err)
  } catch (err) {
    return Promise.reject(err)
  }
})

export default apiClient
