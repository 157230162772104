<template>
  <div :class="$style.footer">
    <div :class="$style.footerInner">
      <div
        :class="$style.logo"
        class="d-flex align-items-center"
      >
        <img src="img/Apresia Logo.png" width="36" alt="logo">
        <strong class="ml-2">{{ settings.logo }}</strong>
      </div>
      <p class="mb-0">
        Copyright © {{ new Date().getFullYear() }}
      </p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const settings = computed(() => store.getters.settings)

    return {
      settings,
    }
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
