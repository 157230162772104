import Vue from 'vue'
import apiClient from '@/services/axios'
import { getAllUser } from '@/services/axios/admin/user'
import { getContentUser, getDetailUser, getStoryUser } from '../../services/axios/admin/user'
import { method } from 'lodash'
// import router from '@/router'

export default {
  namespaced: true,
  state: {
    accounts: [
      {
        key: 0,
        firstName: 'John',
        lastName: 'Doe',
        email: 'john_doe@mail.com',
        userName: 'johnHandsomeGuy123',
        ktp: '31813801337',
        gender: 'Pria',
        idImage:
          'https://image.shutterstock.com/image-vector/ktp-indonesia-id-card-vector-260nw-1839101338.jpg',
        plan: 'Supporter',
        selfie: 'https://portalpulsa.com/images/tips-photo-selfie.jpg',
        bankAccountNumber: '316231223',
        posts: [
          {
            key: 0,
            banner: '',
            sentence: 'Lorem ipsum dolor sit amet',
            isPublic: true,
            isPremium: true,
            isPublished: true,
            createdAt: '2022-01-11',
            coinAmount: 100000,
            category: 'Business',
            countComments: 50,
            countLikes: 100,
            content: [
              {
                filename: 'filename.png',
                filepath:
                  'https://images.squarespace-cdn.com/content/v1/5702ab9d746fb9634796c9f9/1604297962541-T1UG7C2OK1CGQ4MPCK24/original.jpg',
                filetype: 'img',
              },
            ],
          },
          {
            key: 1,
            banner: '',
            sentence: 'Lorem ipsum dolor sit amet',
            isPublic: true,
            isPremium: true,
            isPublished: true,
            isTrending: true,
            createdAt: '2022-01-11',
            coinAmount: 100000,
            countLikes: 100,
            category: 'Finance',
            countComments: 50,
            content: [
              {
                filename: 'filename.png',
                filepath:
                  'https://storage.googleapis.com/stateless-wknc-org/sites/1/2021/05/Inevitable_end_of_corridor_2098072225-e1621016378265.jpg',
                filetype: 'img',
              },
            ],
          },
        ],
        verifiedAt: '2022/14/01',
      },
    ],
    selectedAccount: {},
    contents: [],
    stories: [],
    menuSelected: 'Detail Akun',
    reports: [
      {
        key: 0,
        account: 'John Doe',
        post: 1,
        message: 'Explicit Content',
      },
    ],
    views: [
      {
        key: 0,
        account: 'John Doe',
        viewer: [0, 1, 2, 3, 4, 5, 6, 7],
      },
    ],
    trendings: [
      {
        key: 0,
        position: 1,
        account: 'John Doe',
        post: 1,
        likes: 1400,
        shares: 500,
        comments: 800,
        weight: 600,
        customWeight: 600,
        dateWeight: 500,
        createdAt: '2022/01/14',
      },
    ],
    transactions: [
      {
        giverId: 'John Doe',
        creatorId: 'Nick Gru',
        createdAt: '2021/07/21',
        type: 'Top Up',
        external_id: 'INV/20232323/331',
        coinAmount: 100000,
        xenditUrl: 'http://xenit.com',
        paymentMethod: 'DANA',
        status: 'Paid',
      },
    ],
    topups: [
      {
        account: 'John Doe',
        createdAt: '2021/07/21',
        type: 'Top Up',
        external_id: 'INV/20232323/331',
        totalPrice: 100000,
        xenditUrl: 'http://xenit.com',
        paymentMethod: 'DANA',
        status: 'Paid',
      },
    ],
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    SET_STATE_PER_FIELD(state, payload) {
      state[payload.type] = payload.data
    },
    SET_DETAIL(state, payload) {
      state.selectedAccount = payload
    },
  },
  actions: {
    async FETCH_ALL_AKUN_CUSTOMER({}, search) {
      try {
        const {
          data: { data },
        } = await apiClient.get('/user/customer', { params: { search } })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_DETAIL_AKUN({}, id) {
      try {
        const {
          data: { data },
        } = await apiClient.get(`/user/${id}`)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_MYCOURSE({}, id) {
      try {
        const {
          data: { data },
        } = await apiClient.get(`/my-course/${id}`)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async TAKEDOWN_MYCOURSE({}, id) {
      try {
        console.log(id)
        const { data: res } = await apiClient.delete(`/my-course/takedown/${id}`)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADD_AKUN_CUSTOMER(_, data) {
      try {
        const { data: res } = await apiClient.post('/user/register', data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_AKUN_CUSTOMER(_, { data, id }) {
      try {
        const { data: res } = await apiClient('/user/' + id, {
          method: 'PUT',
          data: {
            editUserObj: data,
          },
        })
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_AKUN_CUSTOMER(_, { id }) {
      try {
        const { data: res } = await apiClient.delete('/user/' + id)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async ADMIN_FETCH_ALL_USER(context, params) {
      try {
        const res = await getAllUser(params)
        if (res.data) {
          for (const item of res.data) {
            // console.log(item._id, '<<<<Item')
            const detail = await getDetailUser({ _id: item._id })
            item['userDetail'] = { ...detail.data }
          }
          context.commit('SET_STATE_PER_FIELD', { data: res.data, type: 'accounts' })
        }
        console.log(res)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_USER_DETAIL(context, params) {
      try {
        const res = await getDetailUser({ _id: params._id })
        context.commit('SET_STATE_PER_FIELD', { data: res.data, type: 'selectedAccount' })
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_USER_CONTENT(context, params) {
      try {
        const res = await getContentUser({ _id: params._id, ...params })
        context.commit('SET_STATE_PER_FIELD', { data: res.data, type: 'contents' })
        return new Promise((resolve, rejet) => {
          return resolve(res)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_USER_STORY(context, params) {
      try {
        const res = await getStoryUser({ _id: params._id, ...params })
        context.commit('SET_STATE_PER_FIELD', { data: res.data, type: 'stories' })
        console.log(res)
        return new Promise((resolve, rejet) => {
          return resolve(res)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_EDIT_USER(context, params) {
      try {
        const res = await apiClient({
          url: `/admin/user/${params._id}`,
          method: 'PATCH',
          data: {
            firstName: params.firstName,
            lastName: params.lastName,
            birthday: params.birthday,
          },
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_WITHDRAWAL_DEPOSIT_USER(context, params) {
      try {
        const { _id, ...rest } = params
        const res = await apiClient({
          url: `/admin/creator/wallet/diamond-history?idUser=${_id}`,
          method: 'GET',
          params: { ...rest },
        })
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (error) {
        return new Promise((resolve, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_DETAIL_WITHDRAWAL_DEPOSIT_USER(context, params) {
      try {
        const { _id, _detailId, ...rest } = params
        const res = await apiClient({
          url: `/admin/creator/wallet/diamond-history/${_detailId}?idUser=${_id}`,
          method: 'GET',
          params: { ...rest },
        })
        return new Promise((resolve, _) => {
          resolve(res.data)
        })
      } catch (error) {
        return new Promise((_, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_TRANSACTION_USER(context, params) {
      try {
        const { _id, ...rest } = params
        const res = await apiClient({
          url: `/admin/user/transaction-history?idUser=${_id}`,
          method: 'GET',
          params: { ...rest },
        })
        return new Promise((resolve, _) => {
          resolve(res)
        })
      } catch (error) {
        return new Promise((_, reject) => {
          reject(error)
        })
      }
    },
    async ADMIN_FETCH_DETAIL_TRANSACTION(context, params) {
      try {
        const { _id, _detailId, ...rest } = params
        const res = await apiClient({
          url: `/admin/user/transaction-history/${_detailId}?idUser=${_id}`,
          method: 'GET',
          params: { ...rest },
        })
        return new Promise((resolve, _) => {
          resolve(res.data)
        })
      } catch (error) {
        return new Promise((_, reject) => {
          reject(error)
        })
      }
    },
  },
  modules: {},
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
