import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_BANNER({}, search) {
      try {
        const { data: { data } } = await apiClient.get('/banner', { params: { search } })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async FETCH_BANNER_BY_COURSE(_, { idCourse }) {
      try {
        const { data: { data } } = await apiClient.get('/banner/course/' + idCourse)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async CREATE_BANNER_COURSE(_, { idCourse, payload }) {
      try {
        const { data: { data } } = await apiClient.post('/banner/course', { ...payload, courseId: idCourse })
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_BANNER_COURSE(_, { idCourse, payload }) {
      try {
        const { data: { data } } = await apiClient.put('/banner/course/' + idCourse, payload)
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_BANNER(_, { data, id }) {
      try {
        const { data: res } = await apiClient.put('/banner/update/' + id, data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_BANNER(_, { id }) {
      try {
        const { data: res } = await apiClient.delete('/banner/delete/' + id)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {
  },
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
