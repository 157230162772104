<template>
  <div :class="$style.topbar" class="d-flex justify-content-between">
    <div class="mr-auto mr-md-1">
      <!-- <vb-search /> -->
    </div>
    <div>
      <vb-user-menu />
    </div>
  </div>
</template>

<script>
// import VbSearch from './Search'
import VbUserMenu from './UserMenu'

export default {
  components: {
    // VbSearch,
    VbUserMenu,
  },
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>
