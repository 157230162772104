export const getMenuData = [
  // VB:REPLACE-START:MENU-CONFIG
  // {
  //   title: 'Dasbor',
  //   key: '__dasbor',
  //   url: '/dasbor',
  //   icon: 'fe fe-home',
  // },
  {
    title: 'Insight',
    key: '__tingkat',
    url: '/dashboard',
    icon: 'fe fe-database',
  },
  {
    category: true,
    title: 'Master Data',
  },
  {
    title: 'Topik',
    key: '__topic',
    url: '/topic',
    icon: 'fe fe-grid',
  },
  {
    title: 'Trending',
    key: '__trending',
    url: '/trending',
    icon: 'fe fe-trending-up',
  },
  {
    title: 'Views Terbanyak',
    key: '__mostViewed',
    url: '/most-views',
    icon: 'fe fe-eye',
  },
  {
    category: true,
    title: 'User Management',
  },
  {
    title: 'Akun',
    key: '__account',
    url: '/account',
    icon: 'fe fe-users',
  },
  {
    title: 'Verifikasi',
    key: '__verification',
    url: '/verification',
    icon: 'fe fe-user-check',
  },
  {
    title: 'Laporan',
    key: '__reports',
    url: '/reports',
    icon: 'fe fe-flag',
  },
  {
    title: 'Transaksi',
    key: '__transaction',
    url: '/transaction',
    icon: 'fe fe-shopping-cart',
  },
  {
    title: 'Penarikan',
    key: '__withdrawal',
    url: '/withdrawal',
    icon: 'fe fe-dollar-sign',
  },
  {
    category: true,
    title: 'Broadcast',
  },
  {
    title: 'Message',
    key: '__message',
    url: '/message',
    icon: 'fe fe-message-square',
  },

  // VB:REPLACE-END:MENU-CONFIG
]
