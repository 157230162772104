import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {
    topics: [
      {
        key: 0,
        title: "Topic 1",
        description: "topic1",
        subtopics: [
          1, 2, 3
        ]
      },
      {
        key: 1,
        title: "Topic 2",
        description: "topic2",
        subtopics: [],
      },
      {
        key: 2,
        title: "Topic 3",
        description: "topic3",
        subtopics: [],
      },
      {
        key: 3,
        title: "Topic 4",
        description: "topic4",
        subtopics: [],
      },
    ],
    messages: [
      {
        key: 0,
        title: "tes",
        message: "tes",
      },
    ]
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
    ADD_CATEGORY(state, payload) {
      state.categories.push(payload)
    },
  },
  actions: {
    // async FETCH_CATEGORY({}, search) {
    //   try {
    //     const {
    //       data: { data },
    //     } = await apiClient('/category', {
    //       method: 'GET',
    //       params: { search },
    //     })
    //     return new Promise((resolve, reject) => {
    //       resolve(data)
    //     })
    //   } catch (err) {
    //     return new Promise((resolve, reject) => {
    //       reject(err)
    //     })
    //   }
    // },
    // async ADD_CATEGORY(_, data) {
    //   try {
    //     const { data: res } = await apiClient.post('/category', data)
    //     return new Promise((resolve, reject) => {
    //       resolve(res)
    //     })
    //   } catch (err) {
    //     return new Promise((resolve, reject) => {
    //       reject(err)
    //     })
    //   }
    // },
    ADD_CATEGORY(context, data) {
      context.commit("ADD_CATEGORY", data)
    },
    async UPDATE_CATEGORY(_, { data, id }) {
      try {
        const { data: res } = await apiClient.put('/category/' + id, data)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async DELETE_CATEGORY(_, { id }) {
      try {
        const { data: res } = await apiClient.delete('/category/' + id)
        return new Promise((resolve, reject) => {
          resolve(res)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {
  },
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
