import Vue from 'vue'
import apiClient from '@/services/axios'
// import router from '@/router'

export default {
  namespaced: true,
  state: {
  },
  mutations: {
    SET_STATE(state, payload) {
      Object.assign(state, {
        ...payload,
      })
    },
  },
  actions: {
    async FETCH_ADMIN_BY_ID() {
      try {
        const { data: { data } } = await apiClient.get('/user/by-id')
        return new Promise((resolve, reject) => {
          resolve(data)
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
    async UPDATE_ADMIN_PROFILE({ commit }, { newAdminProfile }) {
      try {
        await apiClient.put('/user/edit-profile/admin', newAdminProfile)
        return new Promise((resolve, reject) => {
          resolve({ isSuccess: true })
        })
      } catch (err) {
        return new Promise((resolve, reject) => {
          reject(err)
        })
      }
    },
  },
  modules: {
  },
  getters: {
    // userDetail() {
    //   return Vue.$cookies.get('user')
    // },
  },
}
