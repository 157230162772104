import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import AuthLayout from '@/layouts/Auth'
import MainLayout from '@/layouts/Main'
import store from '@/store'
import cookies from 'vue-cookies'

const router = createRouter({
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: MainLayout,
      meta: {
        authRequired: false,
      },
      children: [
        {
          path: '/dashboard',
          name: 'Dasbor',
          meta: { title: 'Dasbor' },
          component: () => import('./views/dashboard'),
        },
        {
          path: '/profil',
          name: 'Profil Saya',
          meta: { title: 'Profil Saya' },
          component: () => import('./views/admin/profile'),
        },
        {
          path: '/master',
          name: 'Master',
          redirect: { name: 'Kategori' },
          meta: { title: 'Master' },
        },
        {
          path: '/topic',
          name: 'Topic',
          meta: { title: 'Topic' },
          component: () => import('./views/topic'),
        },
        {
          path: '/message',
          name: 'Message',
          meta: { title: 'message' },
          component: () => import('./views/message'),
        },
        {
          path: '/verification',
          name: 'Verification',
          meta: { title: 'Verification' },
          component: () => import('./views/verification'),
        },
        {
          path: '/sub-category',
          name: 'Sub Kategori',
          meta: { title: 'Sub Kategori' },
          component: () => import('./views/subCategory'),
        },
        {
          path: '/master/tingkat',
          name: 'Tingkat',
          meta: { title: 'Tingkat' },
          component: () => import('./views/master/tingkat'),
        },
        {
          path: '/master/vendor',
          name: 'Vendor',
          meta: { title: 'Vendor' },
          component: () => import('./views/master/vendor'),
        },
        {
          path: '/course',
          name: 'Course',
          meta: { title: 'Course' },
          component: () => import('./views/course'),
        },
        {
          path: '/course/:id',
          name: 'Detail Course',
          meta: { title: 'Detail Course' },
          component: () => import('./views/course/detail'),
        },
        {
          path: '/course/new',
          name: 'Buat Course',
          meta: { title: 'Buat Course' },
          component: () => import('./views/course/new'),
        },
        {
          path: '/account',
          name: 'Account',
          meta: { title: 'Account' },
          component: () => import('./views/akun'),
        },
        {
          path: '/account/detail/:id',
          name: 'Detail Akun',
          meta: { title: 'Akun' },
          component: () => import('./views/akun/detail'),
        },
        {
          path: '/account/detail/:id/post/:post',
          name: 'Detail Post Akun',
          meta: { title: 'Post Detail Akun' },
          props: true,
          component: () => import('./views/akun/detail/post'),
        },
        {
          path: '/reports',
          name: 'Reports',
          meta: { title: 'Reports' },
          component: () => import('./views/reports'),
        },
        {
          path: '/trending',
          name: 'Trending',
          meta: { title: 'Trending' },
          component: () => import('./views/trending'),
        },
        {
          path: '/most-views',
          name: 'Most Views',
          meta: { title: 'MV' },
          component: () => import('./views/viewCount'),
        },
        {
          path: '/transaction',
          name: 'Transaksi',
          meta: { title: 'Transaksi' },
          component: () => import('./views/transaction'),
        },
        {
          path: '/withdrawal',
          name: 'Penarikan',
          meta: { title: 'Penarikan' },
          component: () => import('./views/withdrawal'),
        },
      ],
    },

    // System Pages
    {
      path: '/',
      component: AuthLayout,
      meta: { authRequired: false, isAuth: true },
      name: 'auth',
      children: [
        {
          path: '/masuk',
          name: 'masuk',
          meta: {
            title: 'Masuk',
          },
          component: () => import('@/views/auth/login'),
        },
        {
          path: 'register',
          meta: {
            title: 'Sign Up',
          },
          component: () => import('./views/auth/register'),
        },
        {
          path: '/forgot-password',
          name: 'Lupa Password',
          meta: {
            title: 'Lupa Password',
          },
          component: () => import('./views/auth/forgot-password'),
        },
        {
          path: '/lockscreen',
          meta: {
            title: 'Lockscreen',
          },
          component: () => import('./views/auth/lockscreen'),
        },
        {
          path: '/email-verified',
          name: 'VerifyEmail',
          meta: {
            title: 'Verify Email',
          },
          component: () => import('./views/auth/user/verify-email'),
        },
        {
          path: '/user/forgot-password',
          name: 'UserForgotPassword',
          meta: {
            title: 'User Forgot Password',
          },
          component: () => import('./views/auth/user/forgot-password'),
        },
      ],
    },
    {
      path: '/404',
      name: 'route404',
      meta: {
        title: 'Error 404',
      },
      component: () => import('./views/auth/404'),
    },
    {
      path: '/500',
      meta: {
        title: 'Error 500',
      },
      component: () => import('./views/auth/500'),
    },

    // Redirect to 404
    {
      path: '/:pathMatch(.*)*',
      redirect: { name: 'route404' },
    },
  ],
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  setTimeout(() => {
    NProgress.done()
  }, 300)
  const sessionId = cookies.get('sessionId')
  // console.log(to, from)
  if (to.matched.some(record => record.meta.authRequired)) {
    if (!sessionId) {
      next({
        name: 'masuk',
        // query: { redirect: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    if (to.matched.some(record => record.meta.isAuth) && sessionId) next({ name: 'Master' })
    next()
  }
})

export default router
